body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  font-family: "Poppins", sans-serif !important;
}

span{
  font-family: "Poppins", sans-serif !important;
}

a{
  font-family: "Poppins", sans-serif !important;
}

.MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

p ~ ol, p ~ ul,ol,ul {
  font-family: "Poppins", sans-serif !important;
  line-height: 2;
}


/* ol {
 list-style-type: "1"; 
} */

/* ul {
  list-style-type: "a";
} */